'use strict';
/* global VXConfig */
import Dispatcher              from '../Dispatcher';
import Constants               from '../Constants';
import FluxEventEmitter        from '../FluxEventEmitter';
import assign                  from 'object-assign';
import {doFetch, urlWithQuery} from '../../utils/CommonUtils';
import Flux                    from "../Flux";
import {trackPurchase}         from '../../utils/DataLayerHelper';
import EnumGA4Events           from '../../utils/EnumGA4Events';

const VXMODELS_FEED_URL = VXConfig.services.vxfeed.url;
const VXMODELS_TOKEN    = VXConfig.services.vxfeed.accessToken;
const IS_COIN_USER      = VXConfig.guest?.isCoinUser ?? false;
const _posts            = [];
let _currentTimes       = [];
const _selectedPostId   = [];
const _hasNextPage      = [];
let _isVideoMuted       = true;
let _isBlockVideoMuted  = true;

const CHANGE_EVENTS = {
	FeedOverlay:    'feedOverlay',
	FeedLike:       'feedLike',
	UpdatePost:     'updatePost',
	SetPosts:       'setPosts',
	VideoBlockPlay: 'videoBlockPlay',
	VideoPlay:      'videoPlay',
};

const POST_PRICE   = IS_COIN_USER ? 'priceCoins' : 'price';
const TIPPING_GOAL = {
    goal: IS_COIN_USER ? 'goalCoins' : 'goal',
    tipsSum: IS_COIN_USER ? 'tipsSumCoins' : 'tipsSum',
};

const FeedActionTypes = Constants.ActionTypes.Feed;

/**
 *
 * @param {string} query
 * @param {Object} variables
 * @param {Object} queryParam
 * @returns {Promise}
 */
function doVxModelsRequest(query, variables = null, queryParam = {}) {
	const queryObject = {};
	queryObject.query = query;

	if (variables) {
		queryObject.variables = variables;
	}

	const requestUrl = urlWithQuery(VXMODELS_FEED_URL, {
		...queryParam,
	});

	const init = {
		headers: {'Authorization': 'Bearer ' + (Flux.Guest.isLoggedIn() ? VXMODELS_TOKEN : "")},
	};

	return doFetch(requestUrl, queryObject, Constants.HttpMethods.POST, false, init);
}

const FeedStore = assign({}, FluxEventEmitter.prototype, {
	addOverlayChangeListener:       function(callback) {
		this.on(CHANGE_EVENTS.FeedOverlay, callback);
	},
	removeOverlayChangeListener:    function(callback) {
		this.removeListener(CHANGE_EVENTS.FeedOverlay, callback);
	},
	addLikeChangeListener:          function(callback) {
		this.on(CHANGE_EVENTS.FeedLike, callback);
	},
	removeLikeChangeListener:       function(callback) {
		this.removeListener(CHANGE_EVENTS.FeedLike, callback);
	},
	addUpdatePostChangeListener:    function(callback) {
		this.on(CHANGE_EVENTS.UpdatePost, callback);
	},
	removeUpdatePostChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.UpdatePost, callback);
	},
	addSetPostsChangeListener:      function(callback) {
		this.on(CHANGE_EVENTS.SetPosts, callback);
	},
	removeSetPostsChangeListener:   function(callback) {
		this.removeListener(CHANGE_EVENTS.SetPosts, callback);
	},
	getFeedData(args, callback, withTotal) {
		getFeedData(args, callback, withTotal);
	},
	getFeedTotals(args, callback) {
		getFeedTotals(args, callback);
	},
	getFeedPost(postId, callback) {
		getFeedPost(postId, callback);
	},
	viewedPost(postId, callback) {
		viewedPost(postId, callback);
	},
	buyPost(postId, callback) {
		buyPost(postId, callback);
	},
	likePost(postId, callback) {
		likePost(postId, callback);
	},
	unlikePost(postId, callback) {
		unlikePost(postId, callback);
	},
	commentPost(postId, text, repliesToCommentId, callback) {
		commentPost(postId, text, repliesToCommentId, callback);
	},
	deleteComment(commentID, callback) {
		deleteComment(commentID, callback);
	},
	addTip(postId, amount, trackerContext, callback) {
		addTip(postId, amount, trackerContext, callback);
	},
	setPosts(posts, feedType, hasNextPage) {
		setPosts(posts, feedType, hasNextPage);
	},
	getPosts(feedType) {
		return getPosts(feedType);
	},
	getHasNextPage(feedType) {
		return getHasNextPage(feedType);
	},
	setSelectedPostId(id, feedType) {
		setSelectedPostId(id, feedType);
	},
	getSelectedPostId(feedType) {
		return getSelectedPostId(feedType);
	},
	setCurrentTime(id, time) {
		setCurrentTime(id, time);
	},
	removeCurrentTime(id) {
		removeCurrentTime(id);
	},
	getCurrentTime(id) {
		return getCurrentTime(id);
	},
	addBlockVideoPlayChangeListener:    function(callback) {
		this.on(CHANGE_EVENTS.VideoBlockPlay, callback);
	},
	removeBlockVideoPlayChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.VideoBlockPlay, callback);
	},
	setBlockVideoMute(isMuted) {
		_isBlockVideoMuted = isMuted;
	},
	getBlockVideoMute() {
		return _isBlockVideoMuted;
	},
	addVideoPlayChangeListener:    function(callback) {
		this.on(CHANGE_EVENTS.VideoPlay, callback);
	},
	removeVideoPlayChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.VideoPlay, callback);
	},
	setVideoMute(isMuted) {
		_isVideoMuted = isMuted;
	},
	getVideoMute() {
		return _isVideoMuted;
	},
});

function toggleOverlay(postId, overlayType, ImageId) {
	FeedStore.emit(CHANGE_EVENTS.FeedOverlay, postId, overlayType, ImageId);
}

function toggleLike(postId) {
	FeedStore.emit(CHANGE_EVENTS.FeedLike, postId);
}

function triggerUpdatePost(postId) {
	FeedStore.emit(CHANGE_EVENTS.UpdatePost, postId);
}

function triggerSetPosts() {
	FeedStore.emit(CHANGE_EVENTS.SetPosts);
}

function toggleBlockVideoPlay(postId) {
	FeedStore.emit(CHANGE_EVENTS.VideoBlockPlay, postId);
}

function setPlayingVideoId(postId) {
	FeedStore.emit(CHANGE_EVENTS.VideoPlay, postId, _isVideoMuted);
}

const clipPost = `
... on ClipPost {
	clip {
		id
		length
		width
		height
		ratio
		avsRequired
		sources {
			label
			src
			type
		}
		poster {
			id
			url(size: W640)
			srcSet
		}
	}
}`;

const textPost = `
... on TextPost {
	id
	created
	type
	status
	pinned
	title
	text
	views
	likes
	umaId
	__typename
}`;

const photoPost = `
... on PhotosPost {
	id
	created
	type
	status
	pinned
	title
	text
	views
	likes
	umaId
    contest {
        id
    }
	photos {
	  id
	  url
	  srcSet
	  avsRequired
	}
  }`;

const user = `
	name
	id
	avatar {
	  id
	  url(size: W320)
	  srcSet
	  fsk
	  avsRequired
	}
`;

const comment = `
	totalCount
	nodes {
		comment {
			id
			conversationId
			parentId
			created
			isCreator
			tipped
			text
			isReplyToReply
			user {
				${user}
			}
		}
		replies {
			id
			conversationId
			parentId
			created
			isCreator
			tipped
			text
			isReplyToReply
			user {
				${user}
			}
		}
	}
`;

const post = `
	__typename
	id
	created
	model {
		online
		${user}
	}
	type
	status
	pinned
	title
	text
	views
	likes
	umaId
	paidContent
	fsk
	${POST_PRICE}
	tippingGoal {
		${TIPPING_GOAL.goal}
		${TIPPING_GOAL.tipsSum}
		progressPercent
		achieved
	}
	guestState {
		liked
		viewed
		following
		bought
	}
	comments {
		${comment}
	}
	${clipPost}
	${textPost}
	${photoPost}
`;

function getFeedPost(postId, callback) {
	const query = `
	query GetPost($postId: ID!) {
		post(postId: $postId) {
			${post}
		}
	  }
	`;
	doVxModelsRequest(query, {postId}).then(callback);
}

function getFeedData(args, callback, withTotal) {
	let total       = ``;
	let modelIdType = 'ID';

	if (withTotal) {
		modelIdType = 'ID!';
		total       = `
			total: feedTotalCounts(modelId: $modelId) {
				totalCount
			}
		`;
	}
	const query = `
	query GetFeed($first: Int!, $after: String, $modelId: ${modelIdType}, $follows: Boolean, $bought: Boolean, $typeGroups: [PostTypeGroupEnum!], $mode: FeedModeEnum, $liked: Boolean, $commented: Boolean) {
		feed(modelId: $modelId, typeGroups: $typeGroups, follows: $follows, bought: $bought, mode: $mode, liked: $liked, commented: $commented) {
		  id
		  posts(first: $first, after: $after) {
			pageInfo {
				hasNextPage
			}
			edges {
			  cursor
			  node {
				${post}
			  }
			}
			pageInfo {
			  hasNextPage
			}
		  }
		}
		${total}
	  }
	`;
	doVxModelsRequest(query, {...args}).then(callback);
}

function getFeedTotals(args, callback) {
	const query = `
	query GetFeedTotal($modelId: ID!, $follows: Boolean, $bought: Boolean, $liked: Boolean, $commented: Boolean) {
		counts: feedTotalCounts(modelId: $modelId, follows: $follows, bought: $bought, liked: $liked, commented: $commented) {
			totalCount
			clips
			photos
			texts
			liked
			commented
		  }
		bought: feedTotalCounts(modelId: $modelId, bought: true) {
			totalCount
			clips
			photos
			texts
			liked
			commented
		}
	  }
	`;
	doVxModelsRequest(query, {...args}).then(callback);
}

function viewedPost(postId, callback) {
	const mutation = `
	mutation viewedPost($postId: ID!) {
		vx {
			viewedPost(postId: $postId) {
			  id
			}
		}
	  }
	`;
	doVxModelsRequest(mutation, {postId}).then(callback);
}

function buyPost(postId, callback) {
	const mutation = `
	mutation buyPost($postId: ID!) {
		vx {
			buyPost(postId: $postId) {
			  success
			}
		}
	  }
	`;
	doVxModelsRequest(mutation, {postId})
		.then((response) => {
			// track success purchase
			if (response.data && response.data.vx.buyPost.success) {
				trackPurchase({
					[EnumGA4Events.DEFAULT_PARAMETER_TRANSACTION_ID]: postId + ':' + Flux.Guest.getId(),
					[EnumGA4Events.DEFAULT_PARAMETER_ITEMS]:          [{
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME]:     EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_FEED,
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY]: EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_FEED_PAID_CONTENT,
					}]
				});
			}
			return response;
		})
		.then(callback);
}

function likePost(postId, callback) {
	const mutation = `
	mutation likePost($postId: ID!) {
		vx {
			likePost(postId: $postId) {
			  id
			}
		}
	  }
	`;
	doVxModelsRequest(mutation, {postId}).then(callback);
}

function unlikePost(postId, callback) {
	const mutation = `
	mutation unlikePost($postId: ID!) {
		vx{
			unlikePost(postId: $postId) {
			  id
			}
		}
	  }
	`;
	doVxModelsRequest(mutation, {postId}).then(callback);
}

function commentPost(postId, text, repliesToCommentId, callback) {
	const mutation = `
	mutation commentPost($postId: ID!, $text: String!, $repliesToCommentId: ID) {
		vx {
			commentPost(postId: $postId, text: $text, repliesToCommentId: $repliesToCommentId) {
			  id
			}
		}
	  }
	`;
	doVxModelsRequest(mutation, {postId, text, repliesToCommentId}).then(callback);
}

function deleteComment(commentID, callback) {
	const mutation = `
	mutation deleteComment($commentID: ID!) {
		vx {
			deleteComment(commentID: $commentID)
		}
	  }
	`;
	doVxModelsRequest(mutation, {commentID}).then(callback);
}

function addTip(postId, amount, {item_category = EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_FEED_TIP}, callback) {
    let amountCoins = null;

    if (IS_COIN_USER) {
        amountCoins = amount;
        amount      = null;
    }

    const mutation = `
    mutation tipPost($postId: ID!, $amount: Euro, $amountCoins: Coins) {
        vx {
            tipPost(postId: $postId, amount: $amount, amountCoins: $amountCoins) {
              success
            }
        }
    }
	`;

	doVxModelsRequest(mutation, {postId, amount, amountCoins})
		.then((response) => {
			// track success purchase
			if (response.data && response.data.vx.tipPost.success) {
				trackPurchase({
					[EnumGA4Events.DEFAULT_PARAMETER_TRANSACTION_ID]: postId + ':' + Flux.Guest.getId(),
					[EnumGA4Events.DEFAULT_PARAMETER_VALUE]:          amount,
					[EnumGA4Events.DEFAULT_PARAMETER_ITEMS]:          [{
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME]:     EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_FEED,
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY]: item_category,
					}]
				});
			}
			return response;
		})
		.then(callback);
}

function setPosts(data, feedType, hasNextPage = false) {
	_posts[feedType]       = data;
	_hasNextPage[feedType] = data.length === 0 ? false : hasNextPage;
}

function getPosts(feedType) {
	return _posts[feedType] || [];
}

function getHasNextPage(feedType) {
	return _hasNextPage[feedType];
}

function setSelectedPostId(id, feedType) {
	_selectedPostId[feedType] = id;
}

function getSelectedPostId(feedType) {
	return _selectedPostId[feedType] || null;
}

function setCurrentTime(id, time) {
	_currentTimes.push({id, time});
}

function removeCurrentTime(id) {
	_currentTimes = [..._currentTimes].filter(times => times.id !== id);
}

function getCurrentTime(id) {
	const currentTime = _currentTimes.find(times => times.id === id);
	if (currentTime) {
		return currentTime.time;
	}
	return null;
}

FeedStore.dispatchToken = Dispatcher.register((action) => {
	switch (action.type) {
		case FeedActionTypes.TOOGLE_OVERLAY:
			toggleOverlay(action.postId, action.overlayType, action.ImageId);
			break;
		case FeedActionTypes.SET_LIKE:
			toggleLike(action.postId);
			break;
		case FeedActionTypes.TRIGGER_UPDATE_POST:
			triggerUpdatePost(action.postId);
			break;
		case FeedActionTypes.TRIGGER_SET_POSTS:
			triggerSetPosts();
			break;
		case FeedActionTypes.TOGGLE_BLOCK_VIDEO_PLAY:
			toggleBlockVideoPlay(action.postId);
			break;
		case FeedActionTypes.TOGGLE_VIDEO_PLAY:
			setPlayingVideoId(action.postId);
			break;
		default:
	}
});

export default FeedStore;
